// Migrated
<template>
  <div class="min-h-[600px] md:min-h-[500px]">
    <ResponsiveText class="box-title">
      <span>
        {{ $t('solresorStartPage.destinations') }}
      </span>
    </ResponsiveText>
    <SlidesBehindSlider
      :backdrop-color="backdropColor"
      dots-type="turquoise"
    >
      <CharterSimpleBox
        v-for="(destination, idx) in sortedFilteredFormattedDestinations"
        :key="idx"
        class="my-6"
        :text="destination.name"
        :price="destination.price"
        :image="destination.image_cloudinary"
        :url="`${localeURLs.destinations}/${destination.destination_page_id}`"
      />
    </SlidesBehindSlider>
  </div>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { urls: localeURLs } = useLocale()

    return {
      localeURLs,
    }
  },

  props: {
    backdropColor: {
      type: String,
      default: 'light-turquoise',
    },

    preventPrice: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState(useCharterPackagesStore, ['destinations']),

    sortedFilteredFormattedDestinations () {
      return [...this.destinations]
        .filter(({ visible, featured_prio }) => visible && featured_prio)
        .sort((a, b) => b.featured_prio - a.featured_prio)
        .map(destination => ({
          ...destination,
          price: this.preventPrice ? null : destination.price,
        }))
    },
  },
})
</script>
